import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import stableHash from 'stable-hash';
import type { Ref } from 'vue';
import { computed, ref, unref } from 'vue';
import type { KeysOf } from '#app/composables/asyncData';
import type { AsyncDataOptions } from '#app';
import type { HasuraError } from '~/internals/errors/hasura-error.ts';
import { getOperationName } from '~/packages/graphql-client/create-graphql-client.ts';
import { clientHasura } from '~/internals/hasura/client-hasura.ts';
import { clientHasuraNoAuth } from '~/internals/hasura/client-hasura-no-auth.ts';
import type { NetworkError } from '~/internals/errors/network-error.ts';
import { useAsyncData } from '#imports';

export interface AsyncDataGraphqlOptions<ResT, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>>
  extends AsyncDataOptions<ResT, DataT, PickKeys> {
  auth?: boolean;
  customQueryKey?: string;
}

export function useGraphqlQuery<ResT, TVariables, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>>(
  operation: TypedDocumentNode<ResT, TVariables>,
  variables?: Ref<TVariables>,
  { auth, customQueryKey, ...rest }: AsyncDataGraphqlOptions<ResT, DataT, PickKeys> = {},
) {
  return useAsyncData<ResT, HasuraError, DataT, PickKeys>(
    customQueryKey ?? stableHash([getOperationName(operation)!, unref(variables)]),
    () => {
      const requiredAuth = auth ?? true;
      const client = requiredAuth ? clientHasura : clientHasuraNoAuth;
      return client.request<ResT, TVariables>(operation, unref(variables));
    },
    {
      lazy: true,
      ...rest,
    },
  );
}

export interface MutationOptions<TData, TError> {
  auth?: boolean;
  onSuccess?: (data: TData) => void;
  onError?: (err: TError) => void;
}

export type MutationStatus = 'idle' | 'loading' | 'success' | 'error';

// simple replacement for @tanstack/vue-query
export function useGraphqlMutation<TData = any, TVariables = Record<string, any>>(
  operation: TypedDocumentNode<TData, TVariables>,
  options: MutationOptions<TData, HasuraError | NetworkError> = {},
) {
  const error = ref<HasuraError | NetworkError | null>();
  const data = ref<TData | null>();
  const status = ref<MutationStatus>('idle');
  function mutateFn(variables: TVariables) {
    const requiredAuth = options.auth ?? true;
    const client = requiredAuth ? clientHasura : clientHasuraNoAuth;
    return client.request<TData, TVariables>(operation, variables);
  }

  function mutate(variables: TVariables) {
    status.value = 'loading';
    mutateFn(variables)
      .then((resp) => {
        data.value = resp;
        options.onSuccess?.(resp);
        status.value = 'success';
      })
      .catch((err) => {
        error.value = err;
        options.onError?.(err as HasuraError | NetworkError);
        status.value = 'error';
      });
  }

  async function mutateAsync(variables: TVariables) {
    try {
      status.value = 'loading';
      const resp = await mutateFn(variables);
      status.value = 'success';
      options.onSuccess?.(resp);
      return resp;
    } catch (err) {
      status.value = 'error';
      error.value = err as HasuraError | NetworkError | null;
      options.onError?.(error.value!);
      // rethrow
      throw err;
    }
  }

  return {
    data,
    error,
    isLoading: computed(() => status.value === 'loading'),
    isSuccess: computed(() => status.value === 'success'),
    isError: computed(() => status.value === 'error'),
    mutate,
    mutateAsync,
  };
}
