import { useToast } from 'primevue/usetoast';
import type { ToastMessageOptions } from 'primevue/toast';

interface NotificationMessageOptions extends Exclude<ToastMessageOptions, 'severity'> {}

const DEFAULT_LIFE = 6000;

function useNotification() {
  const toast = useToast();

  return {
    success: (options: NotificationMessageOptions) => {
      return toast.add({
        life: DEFAULT_LIFE,
        ...options,
        severity: 'success',
      });
    },
    info: (options: NotificationMessageOptions) => {
      return toast.add({
        life: DEFAULT_LIFE,
        ...options,
        severity: 'info',
      });
    },
    warn: (options: NotificationMessageOptions) => {
      return toast.add({
        life: DEFAULT_LIFE,
        ...options,
        severity: 'warn',
      });
    },
    error: (options: NotificationMessageOptions) => {
      return toast.add({
        life: DEFAULT_LIFE,
        ...options,
        severity: 'error',
      });
    },
  };
}

export default useNotification;
