export abstract class HandledError extends Error {
  abstract _handle(): string;
}

export function handleError(error: Error | HandledError) {
  if ('_handle' in error) {
    return error._handle();
  }

  return null; // now handle yourself
}
